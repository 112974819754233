<!-- 错题单题 -->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';

*{
    box-sizing: border-box;
}
.content{
	padding: 32px 32px 0;
	overflow: auto;
	height: calc(100vh - 120px);
	
}
.office_form{
    margin-bottom: 50px;
    h3{
        text-align: center;
        margin: 30px 0;
    }
    .office{
        margin:0 auto;
        width: 800px;
    }
}
</style>

<template>
    <section class="page">
		<div class="content">
			<div class="questions">
				<div class="type flex_align">
					{{homeworkItem.syque_typ_name?homeworkItem.syque_typ_name:"--"}} 
				</div>
				<div class="topic">
					<div class="val">
						<div class="richblock" v-html="homeworkItem.stque_title"></div>
					</div>
					<span class="c-orange">（{{homeworkItem.syque_typ_name}}）</span>
                    <img class="selectimg" v-if="homeworkItem.stque_image" :src="homeworkItem.stque_image" alt="">
					<!-- <el-image v-if="homeworkItem.stque_image" style="width: 200px; height: 100px" :src="homeworkItem.stque_image" :preview-src-list="[homeworkItem.stque_image]"></el-image> -->
				</div>
				
				<div class="office_form" v-if="homeworkItem.stque_att_paper_url&&homeworkItem.syque_typ_id==99">
					<!-- pdf展示数据--试卷 -->
					<div class="office">
						<vueOffice v-model="homeworkItem.stque_att_paper_url" type="pdf"></vueOffice>
					</div>
				</div>
				<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
				<!-- 1单项选择 2多项选择 3单项填空 4多项填空 5判断 -->
				<div v-if="homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==2||homeworkItem.syque_typ_id==5">
					<div v-for="(item,index) in homeworkItem.question_data" :key="index">
						<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
						<!-- mistake 正确  correct错误  :class="item.stque_ite_isanswer==10?'correct':''"-->
						<div class="li" :class="item.stque_ite_isanswer==10?'correct':''">
							<div class="flex">
								<div class="iconfont icona-dui">&#xe6db;</div>
								<div class="iconfont icona-cuo">&#xe6dc;</div>
                                <div class="number">{{item.stque_ite_code}}.</div>
                                <div class="val">
									<div class="richinline" v-html="item.stque_ite_title"></div>
								</div>
							</div>
							<img class="selectimg" v-if="item.stque_ite_image" :src="item.stque_ite_image" alt="">
							<!-- <el-image v-if="item.stque_ite_image" style="width: 200px; height: 100px" :src="item.stque_ite_image" :preview-src-list="[item.stque_ite_image]"></el-image> -->
                        </div>
					</div>
				</div>
				<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 组合题 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
				<div v-if="homeworkItem.stque_ismakeup==10&&homeworkItem.syque_typ_id!=99">
					<div class="p-0-20">
						<div v-for="(item2,index2) in homeworkItem.question_data" :key="index2">
							<div class="topic">
								<div class="val"><em class="richinline" v-html="item2.stque_title"></em>
									<span class="c-orange">
									（{{item2.syque_typ_name}}）
									</span>
								</div>
								<img class="selectimg" v-if="item2.stque_image" :src="item2.stque_image" alt="">
								<!-- <el-image v-if="item2.stque_image" style="width: 200px; height: 100px" :src="item2.stque_image" :preview-src-list="[item2.stque_image]"></el-image> -->
							</div>
							<div v-if="item2.syque_typ_id==1||item2.syque_typ_id==2||item2.syque_typ_id==5">
								<div v-for="(item,index) in item2.question_child_item" :key="index">
									<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
									<!-- mistake 正确  correct错误  :class="item.stque_ite_isanswer==10?'correct':''"-->
									<div class="li"  :class="item.stque_ite_isanswer==10?'correct':''">
										<div class="flex">
											<div class="iconfont icona-dui">&#xe6db;</div>
								            <div class="iconfont icona-cuo">&#xe6dc;</div>
                                            <div class="number">{{item.stque_ite_code}}.</div>
                                            <div class="val">
												<div class="richinline" v-html="item.stque_ite_title"></div>
											</div>
										</div>
                                        <img class="selectimg" v-if="item.stque_ite_image" :src="item.stque_ite_image" alt="">
										<!-- <el-image v-if="item.stque_ite_image" style="width: 200px; height: 100px" :src="item.stque_ite_image" :preview-src-list="[item.stque_ite_image]"></el-image> -->
									</div>
								</div>
							</div>
							<div class="emphasis">
								<div class="h2 flex_align">
									<div class="iconfont">&#xe6d9;</div>
									答案解析
								</div>
								<div class="p richblock" v-if="item2.stque_analysis" v-html="item2.stque_analysis"></div>
								<div class="noexplain" v-else>暂无数据</div>
							</div>
							<div class="emphasis">
								<div class="h2 flex_align">
									<div class="iconfont">&#xe6da;</div>
									知识点
								</div>
								<div class="p" v-if="item2.tetea_kno_content">{{item2.tetea_kno_content}}</div>
								<div class="noexplain" v-else>暂无数据</div>
							</div>
							<div class="emphasis" v-if="(item2.stque_stuanswer&&item2.stque_stuanswer.length>0)||(item2.stque_stuimages&&item2.stque_stuimages.length>0)">
								<div class="h2 flex_align">
									<div class="iconfont">&#xe6da;</div>
									我的解答
								</div>
								<div v-for="(item,index) in item2.stque_stuanswer" :key="index">
									<div class="li mb-20 richblock" v-if="item" v-html="item"></div>
								</div>
								<div class="imgs" v-if="item2.stque_stuimages">
									<div class="img" v-for="(item,index) in getimglist(item2.stque_stuimages)" :key="index">
                                        <img class="selectimg" v-if="item" :src="item" alt="">
										<!-- <el-image v-if="item" style="width: 140px; height: 140px" :src="item" :preview-src-list="getimglist(item2.stque_stuimages)"></el-image> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="homeworkItem.stque_ismakeup!=10">
				<div class="emphasis" v-if="homeworkItem.syque_typ_id!=99">
					<div class="h2 flex_align">
						<div class="icon iconjiexi"></div>
						答案解析
					</div>
					<div class="p richblock" v-if="homeworkItem.stque_analysis" v-html="homeworkItem.stque_analysis"></div>
					<div class="noexplain" v-else>暂无数据</div>
				</div>
				<div class="emphasis" v-if="homeworkItem.syque_typ_id==99">
					<div class="h2 flex_align">
						<div class="icon iconjiexi"></div>
						答案解析
					</div>
					<div class="office_form" v-if="homeworkItem.stque_stexa_att_analysis_url">
						<div class="office">
							<vueOffice v-model="homeworkItem.stque_stexa_att_analysis_url" type="pdf"></vueOffice>
						</div>
					</div>
					<div class="noexplain" v-else>暂无数据</div>
				</div>
				<div class="emphasis">
					<div class="h2 flex_align">
						<div class="icon iconzhishidian1"></div>
						知识点
					</div>
					<div class="p" v-if="homeworkItem.tetea_kno_content">{{homeworkItem.tetea_kno_content}}</div>
					<div class="noexplain" v-else>暂无数据</div>
				</div>
				<div class="emphasis" v-if="(homeworkItem.stque_stuanswer&&homeworkItem.stque_stuanswer.length)||(homeworkItem.stque_stuimages&&homeworkItem.stque_stuimages.length>0)">
					<div class="h2 flex_align">
						<div class="icon iconzhishidian1"></div>
						我的解答
					</div>
					<div v-for="(item,index) in homeworkItem.stque_stuanswer" :key="index">
						<div class="li mb-20 richblock" v-if="item" v-html="item"></div>
					</div>
					<div class="imgs" v-if="homeworkItem.stque_stuimages">
						<div class="img" v-for="(item,index) in getimglist(homeworkItem.stque_stuimages)" :key="index">
                            <el-image v-if="item" style="width: 140px; height: 140px" :src="item" :preview-src-list="getimglist(homeworkItem.stque_stuimages)"></el-image>
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>
</template>

<script>
import { stuquestionscschidInfo } from "@api/home"
import vueOffice from "@comp/vueOffice"
export default {
    name: 'questionsDetails',
    components: {
        vueOffice
    },
    data() {
        return {
            //科目id
            stque_id:0,
            //单题内容
            homeworkItem:{},
        }
    },
    created() {
        this.stque_id=this.$route.params.id
        this.getData();
    },
    methods: {
       
        /** 获取公开课列表数据 */
        async getData() {
            let params = {
                stque_id:this.stque_id,
            }
            let { data } = await stuquestionscschidInfo(params);
            this.homeworkItem=data
            this.$forceUpdate();
        },
        getimglist(imgs){
            return  imgs.split(',')
        }
      
    },
}
</script>